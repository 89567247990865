import React from 'react';
import Layout from '../components/App';
import { PRE_CSS_LOADER_STYLE } from '../constants';
import { Row } from 'antd';

export default function NotFoundPage() {
  return (
    <Layout title={'Solv'}>
      <Row style={PRE_CSS_LOADER_STYLE as any}>
        <img src="/images/logo/Solv-Logo-DP-319x120.png" alt="Not Found" width={200} />
        <div style={{ marginTop: '20px', color: '#b3b3b3' }}>
          Page Not Found. <a href={'/hello'}>Return Home</a>
        </div>
      </Row>
    </Layout>
  );
}
